import React, { Component } from "react";
import { Link } from 'react-router-dom';

class LoginOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnEmail: '',
      rnPassword: '',
      error: '',
    };
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:4000/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.rnEmail,
          password: this.state.rnPassword,

        }),
      });
      console.log(response)

      if (response.ok) {
        const data = await response.json();
        console.log(response)
        // Handle successful login
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'Login failed.';
        throw new Error(errorMessage);
      }
    } catch (error) {
        console.log(error?.message)
      this.setState({ error: error.message });
    }
  };

  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-8 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Login Now</h2>
                {/* <p className="description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Architecto cupiditate aperiam neque.
                </p> */}
              </div>
              <div className="form-wrapper">
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="item02">
                    <input
                      type="email"
                      name="rnEmail"
                      id="item02"
                      value={this.state.rnEmail}
                      onChange={this.handleInputChange}
                      placeholder="Your email *"
                      required
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="password"
                      name="rnPassword"
                      id="item03"
                      value={this.state.rnPassword}
                      onChange={this.handleInputChange}
                      placeholder="Enter Password"
                      required
                    />
                  </label>

                  <p className="description" style={{ fontSize: '14px', marginBottom: '10px' }}>
                    Don't have an account? <Link to="/register" style={{ color: "#ff4a17" }}>Register</Link>
                  </p>

                  {this.state.error && (
                    <p style={{ color: 'red' }}>{this.state.error}</p>
                  )}

                  <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginOne;