import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Custom Software Development',
        description: 'Develop bespoke software solutions tailored to your specific business needs and requirements.'
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'Create stunning websites that captivate users with eye-catching design and seamless functionality.'
    },
    {
        icon: <FiUsers />,
        title: 'Digital Marketing',
        description: ' Drive brand visibility and reach your target audience through effective online marketing strategies.'
    },
    {
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: ' Build intuitive and high-performance mobile applications for iOS and Android platforms.'
    },
    {
        icon: <FiCast />,
        title: 'Cloud Computing',
        description: ' Scale and optimize your IT resources with flexible and reliable cloud infrastructure.'
    },
    {
        icon: <FiMonitor />,
        title: 'Graphics Designing',
        description: 'Create visually compelling designs that captivate and communicate your brands message effectively.'
    },
]


const starndardService = [
    {
        image: '01',
        title: 'Website Development',
        description: 'Create stunning websites that captivate users with eye-catching design and seamless functionality.',
    },
    {
        image: '02',
        title: 'Application Development',
        description: 'Build intuitive and high-performance mobile applications for iOS and Android platforms.',
    },
    {
        image: '03',
        title: 'Software Development',
        description: 'Develop bespoke software solutions tailored to your specific business needs and requirements.',
    },
    {
        image: '04',
        title: 'Ecommerce',
        description: 'Power your business with our ecommerce solutions, driving sales and enhancing customer experience.',
    },
    {
        image: '05',
        title: 'Graphic Designing',
        description: 'Create visually compelling designs that captivate and communicate your brands message effectively.',
    },
    {
        image: '06',
        title: 'Business Development',
        description: 'Drive business growth with our dynamic business development solutions and maximizing profitability.',
    },

]

const ecommerceServices = [
    {
        image: '01',
        title: 'Amazon Store managment ',
        description: 'Create stunning websites that captivate users with eye-catching design and seamless functionality.',
    },
    {
        image: '02',
        title: 'Product Hunting',
        description: 'Build intuitive and high-performance mobile applications for iOS and Android platforms.',
    },
    {
        image: '03',
        title: 'Store recovery',
        description: 'Develop bespoke software solutions tailored to your specific business needs and requirements.',
    },
]
const ServiceListEcom = [
    {
        icon: <FiCast />,
        title: 'Product Hunting',
        description: ' Stay ahead of the curve by curating innovative and trending products for your business growth.'
    },
    {
        icon: <FiLayers />,
        title: 'Store Management',
        description: 'Streamline operations and optimize performance with our comprehensive store management solutions.'
    },
    {
        icon: <FiUsers />,
        title: 'Account Recovery',
        description: 'Regain access to your accounts swiftly and securely with our expert account recovery services.'
    },
   
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Bixstack' />
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Service'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">Services</h2>
                                    <p>Discover the comprehensive suite of <span className="text">software services</span> offered by Bixstack</p>
                                    <div className="service-btn">
                                        <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {starndardService.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/featured/corporate-${value.image}.png`} alt="Corporate Images"/>
                                                </div>
                                                <div className="content">
                                                    <h3><a href="/contact" >{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                    <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Discuss more</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                   
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Service Area */}

              
              {/* Start Service Area */}
              <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">BixEcom</h2>
                                    <p>Discover the comprehensive suite of <span className="text">Ecommerce services</span>  offered by Bixstack</p>
                                    <div className="service-btn">
                                        <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {ecommerceServices.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/featured/corporate-${value.image}.png`} alt="Corporate Images"/>
                                                </div>
                                                <div className="content">
                                                    <h3><a href="/contact" >{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                    <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Discuss more</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                   
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Service Area */}


                

                {/* Start Service Area */}
                {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Technology</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;