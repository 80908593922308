import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Bixstack' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    {/* <h2 className="title theme-gradient">BixGrab </h2> */}
                                    {/* <p>Streamline Your Upwork Success with One-Click Automation</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Introducing a powerful Google extension designed to streamline the process of creating proposals for <b>Upwork </b> job descriptions with just a single click. This extension revolutionizes the way proposals are generated by enabling a seamless workflow. Once the extension is enabled, a convenient button appears on the proposal interface. Upon clicking the button, a proposal is automatically generated based on the relevant information extracted from the job description, providing a time-saving and efficient solution.

                                                    </p>
                                                    <p>
                                                        Not only does this powerful <b>Google extension</b> simplify the proposal creation process, but it also ensures accuracy and consistency in each generated proposal. By extracting the key details from the job description, freelancers can trust that their proposals are comprehensive and aligned with the client's requirements. This level of precision enhances the professionalism of freelancers' submissions and increases their chances of standing out among other applicants. With the extension's automated and reliable approach, freelancers can confidently submit proposals that are tailored and optimized for success.





                                                    </p>
                                                    <div className="about-button mt--50" style={{margin:0,padding:0}}>
                                                        <a className="rn-button-style--2 btn-solid" href="/pricing-table"  >Get Now</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Furthermore, the Google extension offers a range of customizable options to cater to freelancers' specific needs and preferences. Users have the flexibility to personalize and fine-tune the generated proposals before submission. They can add their own unique touch, showcase relevant experience, or highlight specific skills that make them the ideal candidate for the job. This customization feature empowers freelancers to showcase their expertise and make a compelling case to clients, ultimately increasing their chances of securing projects and expanding their professional opportunities.

                                                    </p>

                                                    <h4 className="title"> Key Points</h4>
                                                    <ul className="liststyle">
                                                        <li>The Google extension automates proposal creation for Upwork job descriptions with a single click.
                                                        </li>
                                                        <li>It extracts relevant information from the job description to generate comprehensive proposals.
                                                        </li>
                                                        <li>The extension offers customization options, allowing freelancers to personalize and fine-tune the generated proposals.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;