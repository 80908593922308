import React, { Component } from "react";
import {Link} from 'react-router-dom'


class RegisterOne extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnPassword: "",
      rnPhoneNumber: ""
    };
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { rnName, rnEmail, rnPassword, rnPhoneNumber } = this.state;
console.log(this.state)
    fetch("http://localhost:4000/users/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: rnName,
        email: rnEmail,
        password: rnPassword,
        phone: rnPhoneNumber
      })
    })
      .then((response) => {
        if (response.ok) {
          console.log("Registration successful!");
        } else {
          throw new Error("Registration failed.");
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-8 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Register Now</h2>
              
              </div>
              <div className="form-wrapper">
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="rnName"
                      id="item01"
                      value={this.state.rnName}
                      onChange={this.handleInputChange}
                      placeholder="Your Name *"
                      required
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="email"
                      name="rnEmail"
                      id="item02"
                      value={this.state.rnEmail}
                      onChange={this.handleInputChange}
                      placeholder="Your email *"
                      required
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="password"
                      name="rnPassword"
                      id="item03"
                      value={this.state.rnPassword}
                      onChange={this.handleInputChange}
                      placeholder="Enter Password"
                      required
                    />
                  </label>

                  <label htmlFor="item04">
  <input
    type="tel"
    name="rnPhoneNumber"
    id="item04"
    value={this.state.rnPhoneNumber}
    onChange={this.handleInputChange}
    placeholder="Enter Phone Number"
    required
  />
</label>
<p className="description" style={{fontSize:'14px',marginBottom:'10px'}}>Already have an account? <Link to="/login" style={{ color: "#ff4a17" }}>
     Login
  </Link>
</p>
{/* <p className="description">
    Already have an account?  <Link to="/login" style={{ color: "#ff4a17" }}>
    Login
  </Link>
</p> */}

                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterOne;