import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiCheck } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";

const PricingTable = () => {
    const handlePurchaseNow = async (id) => {
        try {
            const token =localStorage.getItem('auth-token')
          const response = await fetch('http://localhost:4000/payment/createCustomer', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`, 
            },
            body: JSON.stringify({
              productId: id,
            }),
          });
      
          if (response.ok) {
            const data = await response.json();
            // Redirect to the received URL
            window.location.href = data.url;
          } else {
            const errorData = await response.json();
            const errorMessage = errorData.error || 'Failed to create customer.';
            throw new Error(errorMessage);
          }
        } catch (error) {
          console.log(error?.message);
          // Handle error
        }
      };
      
    return (
        <>
            <PageHelmet pageTitle='Pricing Table' />

            {/* Start Header Area  */}
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            {/* <Breadcrumb title={'Pricing Table'}   /> */}
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Silver</h4>
                                            <div className="pricing">
                                                <span className="price">$0</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 50 hits</li>
                                                <li><FiCheck /> Free Proposal</li>
                                                <li><FiCheck /> Job Success</li>
                                                <li><FiCheck /> User Friendly</li>
                                                {/* <li><FiCheck /> UI/UX designs</li> */}
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing" onClick={()=>handlePurchaseNow(1)}>Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Gold</h4>
                                            <div className="pricing">
                                                <span className="price">$1.00</span>
                                                <span className="subtitle">per hits</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 100 Hits</li>
                                                <li><FiCheck /> Free Proposal</li>
                                                <li><FiCheck /> Job Success</li>
                                                <li><FiCheck /> User Friendly</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing" >Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Advanced</h4>
                                            <div className="pricing">
                                                <span className="price">10</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                            <li><FiCheck /> 10000 Hits</li>
                                                <li><FiCheck /> Free Proposal</li>
                                                <li><FiCheck /> Job Success</li>
                                                <li><FiCheck /> User Friendly</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                            
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
        
    )
}
export default PricingTable;