import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


class Accordion01 extends Component {
    render(){
        return(
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                <AccordionItem >
                    <AccordionItemHeading >
                        <AccordionItemButton >
                        <p style={{fontSize: '24px' }}>
                           Bixstack provides turnkey software solution
                             for promising growth in your business.
                        </p>

                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Bixstack carry all its client through a structured development pipeline resulting in comprehensive software development that meets the requirement</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Sculpturing the idea: designing mockups
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                           
Smartly implementing growth tactics can propel your business towards success by attracting customers, increasing revenue, and expanding market reach.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Creating MVP as a proof of concept
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>

                        The secret of successful business is a combination of innovation, diligent execution, and a strong focus on customer satisfaction, driving sustainable growth and long-term prosperity.                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Integrating into the final product
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>

                        To start a business with business, find a market gap, create a compelling value proposition, and execute with relentless determination and a customer-focused mindset, ensuring sustainable growth and profitability.                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Mainting the product
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>

                        To start a business with business, find a market gap, create a compelling value proposition, and execute with relentless determination and a customer-focused mindset, ensuring sustainable growth and profitability.                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}

export default Accordion01;





