import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import CallAction from "../elements/callaction/CallAction";

import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import LoginOne from "./contact/LoginOne";
import { FiCast , FiLayers , FiUsers ,FiChevronUp , FiCheck ,FiMonitor, FiActivity} from "react-icons/fi";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Login extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        const ServiceList = [
            {
                icon: <FiCheck />,
                title: 'Our Mission',
                description: 'Empower individuals and businesses through innovative solutions that drive growth and success.'
            },
            {
                icon: < FiActivity/>,
                title: 'Our Plan',
                description: 'Execute a strategic roadmap focused on innovation, customer-centricity, and sustainable growth for long-term success.'
            },
            {
                icon: <FiUsers />,
                title: 'Our Vision',
                description: 'Become a global leader, delivering exceptional value and transformative experiences to clients.'
            },
           
        ]
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                


                
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <LoginOne />
                </div>
                {/* End Contact Page Area  */}
                <CallAction />
            <div style={{marginTop:'5rem'}}></div>
                {/* Start Contact Map  */}
                <div className="service-area ptb--40 bg_color--5" >
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Driving Growth and Success</h2>
                                    <p>
At Bixstack, we are driven by a clear mission, guided by a compelling vision, and equipped <br/> with a well-defined plan to achieve our goals.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon" >
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                        />
                        </GoogleMapReact>
                    </div>
                </div> */}
                {/* End Contact Map  */}
                

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}
               
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Login